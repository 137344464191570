;(function () {
	'use strict';

	// lazy load videos
	ready(function() {

        lazyloadElements(
            document.querySelectorAll('.js-load-embed'),
            function(video) {
                var hidden = video.querySelector('[hidden]');
                var content = hidden.innerHTML;
                // get content from within html comment
                content = content.replace(/<!--([\S\s]+?)-->/g, '$1');
                // replace content
                video.innerHTML = content;
                video.classList.remove('js-load-embed');
                video.setAttribute('data-embed-loaded', 'true');


                var scripts = video.querySelectorAll('script');
                if (scripts.length > 0) {
                    Array.prototype.forEach.call(scripts, function (script) {
                        if (script.getAttribute('src') !== null) {
                            loadJS(script.getAttribute('src'), true);
                        }
                    });
                }

            }
        );

	});

}());
